import React, { useEffect, useRef, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from '@reach/router'

import MainMenu from './main-menu'
import Logo from './logo'
import Footer from './footer'
import { StyledHeader, StyledMain } from './styles'
import HeaderNotification from './header-notification'

const Layout = ({ children }) => {
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef()
  const location = useLocation()

  useEffect(() => {
    // https://codepen.io/vsync/pen/bGbmqZe
    const cachedRef = ref.current
    const observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio < 1), {
      threshold: [1],
    })

    observer.observe(cachedRef)

    return function () {
      observer.unobserve(cachedRef)
    }
  }, [])

  return (
    <>
      <HeaderNotification />
      <StyledHeader className={isSticky ? 'is-sticky' : ''} ref={ref}>
        <Logo />
        <MainMenu />
      </StyledHeader>

      <StyledMain>{children}</StyledMain>

      <Footer />

      <CookieConsent
        location="top"
        buttonText="Accepteren"
        declineButtonText="Weigeren"
        cookieName="gdpr-google-analytics"
        enableDeclineButton
        onAccept={() => initializeAndTrack(location)}
        style={{
          minHeight: 'var(--menu-height)',
          alignItems: 'center',
          background: 'var(--color-dark)',
          boxShadow: '0 0 40px rgba(0,0,0,0.5)',
          padding: '0 var(--spacer-large)',
          justifyContent: 'center',
        }}
        buttonStyle={{
          borderRadius: 'var(--border-radius-small)',
          background: 'var(--color-light-yellow)',
          fontSize: '1em',
          fontWeight: 'var(--font-body-weight-semi-bold)',
        }}
        declineButtonStyle={{
          color: 'var(--color-light-yellow)',
          background: 'none',
        }}
        contentStyle={{
          flex: '0 0 1',
        }}
      >
        Wij gebruiken tracking cookies om onze site te verbeteren.
      </CookieConsent>
    </>
  )
}

export default Layout
