import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

import linkResolver from '../lib/link-resolver'
import { Button, StyledNavHorizontal } from './styles'

const StyledMenuButton = styled(Button)`
  display: none;

  @media screen and (max-width: 768px) {
    display: inline-flex;
    align-items: center;

    &:hover > div {
      background: var(--color-light);

      &:before,
      &:after {
        background: var(--color-light);
      }
    }

    > div {
      height: 2px;
      width: 16px;
      background: var(--color-primary);
      margin-right: 0.6rem;
      position: relative;
      transition: background 0.3s;

      &:before,
      &:after {
        content: '';
        height: 2px;
        width: 16px;
        display: block;
        position: absolute;
        left: 0;
        background: var(--color-primary);
        transition: background 0.3s;
      }

      &:before {
        top: -5px;
      }

      &:after {
        top: 5px;
      }
    }
  }
`

const StyledMainMenu = styled(StyledNavHorizontal)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: var(--spacer-small);
    width: 100%;
    padding: var(--spacer-medium);
    background: var(--color-primary);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: opacity 0.3s, visibility 0.3s;

    .is-sticky & {
      margin-top: 0;
    }

    &:after {
      content: '';
    }

    a {
      margin: 0.5em 0;
      color: var(--color-light);

      &:hover {
        color: var(--color-secondary);
      }

      &.current-page {
        color: var(--color-secondary);
      }
    }
  }
`

export const MenuItem = ({ item }) => {
  if (!item.label && !item.page.uid) {
    return null
  }
  const link = linkResolver(item.page)

  return (
    <Link to={link} activeClassName="current-page" partiallyActive={true}>
      {item.label}
    </Link>
  )
}

const MainMenu = () => {
  const { prismicMenu } = useStaticQuery(query)
  const [toggle, setToggle] = useState(false)

  return (
    <>
      <StyledMenuButton onClick={() => setToggle(!toggle)}>
        <div />
        Menu
      </StyledMenuButton>
      <StyledMainMenu isOpen={toggle}>
        <Link to="/" activeClassName="current-page">
          Home
        </Link>
        {prismicMenu.data.main_menu?.map((item) => (
          <MenuItem key={item.page.uid} item={item} />
        ))}
      </StyledMainMenu>
    </>
  )
}

export default MainMenu

export const query = graphql`
  query MainMenuQuery {
    prismicMenu {
      data {
        main_menu {
          label
          page {
            uid
            type
          }
        }
      }
    }
  }
`
