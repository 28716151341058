import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { devices } from '../lib/constants'
import { MenuItem } from './main-menu'
import { StyledMain } from './styles'

const StyledFooter = styled.footer`
  margin-top: var(--spacer-large);
  padding: var(--spacer-large) 0;
  color: var(--color-light);
  background: var(--color-dark);

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    margin-top: 2rem;

    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  ${StyledMain} {
    display: grid;
    grid-gap: var(--spacer-large);

    @media ${devices.laptop} {
      justify-items: flex-end;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: var(--spacer-medium);
    }

    > div:first-of-type {
      grid-column: 1 / 3;
      order: 1;
      justify-self: flex-start;

      @media ${devices.laptop} {
        order: 0;
      }

      > div {
        display: flex;
        align-items: center;

        a {
          margin-right: 4rem;
        }

        img {
          width: 100%;
          max-width: 210px;
          max-height: 60px;
        }
      }
    }
  }
`

const Footer = () => {
  const { prismicMenu, mmaLogo, ndpLogo } = useStaticQuery(query)

  return (
    <StyledFooter>
      <StyledMain as="div">
        <div>
          <p>Trust in Media is een initiatief van:</p>
          <div>
            <a href="http://mma.nl" target="_blank" rel="noreferrer">
              <img src={mmaLogo.publicURL} alt="Magazine Media Associatie" />
            </a>
            <a href="https://www.ndpnieuwsmedia.nl/" target="_blank" rel="noreferrer">
              <img src={ndpLogo.publicURL} alt="NDP Nieuwsmedia" />
            </a>
          </div>
        </div>
        <div>
          <h4>Community</h4>
          <ul>
            {prismicMenu.data.footer_social?.map((item) => (
              <li key={item.link.url}>
                <a href={item.link.url} target="_blank" rel="noreferrer">
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h4>Trust in Media</h4>
          <ul>
            {prismicMenu.data.footer_menu?.map((item) => (
              <li key={item.page.uid}>
                <MenuItem item={item} />
              </li>
            ))}
          </ul>
        </div>
      </StyledMain>
    </StyledFooter>
  )
}
export default Footer

export const query = graphql`
  {
    mmaLogo: file(relativePath: { eq: "mma-logo-white.svg" }) {
      publicURL
    }

    ndpLogo: file(relativePath: { eq: "logo-ndp-nieuwsmedia.svg" }) {
      publicURL
    }

    prismicMenu {
      data {
        footer_social {
          label
          link {
            url
          }
        }
        footer_menu {
          label
          page {
            uid
            type
          }
        }
      }
    }
  }
`
