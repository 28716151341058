import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'

import GatsbyLink from './gatsby-link'

const StyledHeaderNotification = styled.div`
  font-size: 1.5rem;
  line-height: 1;
  padding: 1rem 4rem;
  color: var(--color-light);
  text-align: center;
  background: linear-gradient(to bottom right, var(--color-primary), var(--color-secondary));

  .hero-offset & {
    background: var(--color-dark);
  }

  a {
    color: inherit;
  }
`

const HeaderNotification = () => {
  const { prismicMenu } = useStaticQuery(query)

  if (!prismicMenu.data.header_notification.text) {
    return null
  }

  return (
    <StyledHeaderNotification>
      <RichText render={prismicMenu.data.header_notification.raw} serializeHyperlink={GatsbyLink} />
    </StyledHeaderNotification>
  )
}

export default HeaderNotification

const query = graphql`
  query HeaderNotificationQuery {
    prismicMenu {
      data {
        header_notification {
          raw
          text
        }
      }
    }
  }
`
