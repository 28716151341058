import React from 'react'
import { Link } from 'gatsby'
import { StyledLogo } from './styles'

const Logo = () => {
  const logo = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396.23 161.78">
      <defs><style>.cls-1{fill: var(--color-primary)}</style></defs>
      <path class="cls-1" d="M55.7,10.32A45.39,45.39,0,1,0,101.09,55.7,45.43,45.43,0,0,0,55.7,10.32m0,101.09A55.71,55.71,0,1,1,111.4,55.7a55.77,55.77,0,0,1-55.7,55.71"/>
      <path class="cls-1" d="M55.7,60.69a45.39,45.39,0,1,0,45.39,45.38A45.43,45.43,0,0,0,55.7,60.69m0,101.09a55.71,55.71,0,1,1,55.7-55.71,55.77,55.77,0,0,1-55.7,55.71"/>
      <polygon class="cls-1" points="171.43 27.55 157.12 27.55 157.12 17.78 196.8 17.78 196.8 27.55 182.49 27.55 182.49 67.94 171.43 67.94 171.43 27.55"/>
      <path class="cls-1" d="M195,33.51h10.63V39h.14a13.13,13.13,0,0,1,4-4.78,10.23,10.23,0,0,1,5.88-1.59c.62,0,1.23,0,1.84.07a8.62,8.62,0,0,1,1.7.28v9.71a15.7,15.7,0,0,0-2.23-.54,15.45,15.45,0,0,0-2.3-.17,11.26,11.26,0,0,0-4.82.85,6.41,6.41,0,0,0-2.73,2.37,9.17,9.17,0,0,0-1.24,3.65,37.27,37.27,0,0,0-.28,4.68V67.94H195Z"/>
      <path class="cls-1" d="M255.89,67.94h-10.2V63.26h-.14a12,12,0,0,1-1.46,2A9.33,9.33,0,0,1,241.93,67a12.58,12.58,0,0,1-2.9,1.27,12.16,12.16,0,0,1-3.54.5,15,15,0,0,1-6.56-1.24,9.41,9.41,0,0,1-3.9-3.44,13.52,13.52,0,0,1-1.84-5.17,43.1,43.1,0,0,1-.46-6.45v-19h10.63V50.37c0,1,0,2,.11,3.08a9.86,9.86,0,0,0,.63,2.94,4.57,4.57,0,0,0,4.79,3,6.54,6.54,0,0,0,3.33-.75,5.05,5.05,0,0,0,1.94-2,8.08,8.08,0,0,0,.89-2.8,24.47,24.47,0,0,0,.21-3.26V33.51h10.63Z"/>
      <path class="cls-1" d="M282.34,43.64a7.51,7.51,0,0,0-6.16-2.91,5.72,5.72,0,0,0-2.62.64,2.26,2.26,0,0,0-1.28,2.2,2,2,0,0,0,1.31,1.87,17.2,17.2,0,0,0,3.33,1.07l4.32,1a16.32,16.32,0,0,1,4.33,1.66,9.91,9.91,0,0,1,3.33,3.08,9.24,9.24,0,0,1,1.31,5.21,9.7,9.7,0,0,1-1.45,5.49A11.13,11.13,0,0,1,285,66.41a16.12,16.12,0,0,1-5.11,1.85,30.64,30.64,0,0,1-5.59.53,27.25,27.25,0,0,1-7.3-1,13.62,13.62,0,0,1-6.24-3.65L267.25,57a10.6,10.6,0,0,0,3.3,2.79,8.84,8.84,0,0,0,4.21,1,9.94,9.94,0,0,0,3.33-.53,2,2,0,0,0,1.49-2,2.16,2.16,0,0,0-1.31-2,14.48,14.48,0,0,0-3.33-1.13l-4.32-1a17.86,17.86,0,0,1-4.32-1.6,9,9,0,0,1-3.33-3,9.14,9.14,0,0,1-1.31-5.21A10.47,10.47,0,0,1,262.9,39a10.85,10.85,0,0,1,3.25-3.61,14.12,14.12,0,0,1,4.64-2.09,21.25,21.25,0,0,1,5.32-.67,24.92,24.92,0,0,1,6.87,1,12.78,12.78,0,0,1,5.88,3.54Z"/>
      <path class="cls-1" d="M318.72,42h-9.36V53.48a20.43,20.43,0,0,0,.15,2.59,5.16,5.16,0,0,0,.63,2,3.21,3.21,0,0,0,1.53,1.31,6.66,6.66,0,0,0,2.72.46,18.28,18.28,0,0,0,2.24-.18,3.79,3.79,0,0,0,2.09-.81v8.85a15.84,15.84,0,0,1-3.69.85,32.06,32.06,0,0,1-3.75.22,20.28,20.28,0,0,1-5-.57,11.29,11.29,0,0,1-4-1.81,8.48,8.48,0,0,1-2.66-3.22,11.13,11.13,0,0,1-.95-4.82V42h-6.8v-8.5h6.8V23.3h10.62V33.51h9.36Z"/>
      <path class="cls-1" d="M345.21,33.51h10.63V67.94H345.21Zm-.85-10.84A6.17,6.17,0,1,1,354.88,27a6.17,6.17,0,0,1-10.52-4.35"/>
      <path class="cls-1" d="M363.08,33.51h10.2v4.67h.14a8.42,8.42,0,0,1,1.42-2,10,10,0,0,1,2.2-1.77,12.41,12.41,0,0,1,2.9-1.28,12.17,12.17,0,0,1,3.54-.49A14.6,14.6,0,0,1,390,33.9a9.4,9.4,0,0,1,3.86,3.43,14,14,0,0,1,1.88,5.17,39.5,39.5,0,0,1,.49,6.45v19H385.61V51.08c0-1,0-2-.11-3.09a9.56,9.56,0,0,0-.64-2.94,5,5,0,0,0-1.63-2.19,5,5,0,0,0-3.15-.85,6.62,6.62,0,0,0-3.33.74,5.15,5.15,0,0,0-1.95,2,8.07,8.07,0,0,0-.88,2.8,24.47,24.47,0,0,0-.21,3.26V67.94H363.08Z"/>
      <polygon class="cls-1" points="156.76 93.86 173.48 93.86 185.03 126.59 185.17 126.59 196.79 93.86 213.44 93.86 213.44 144.02 202.39 144.02 202.39 105.55 202.25 105.55 189.07 144.02 180.64 144.02 167.96 105.55 167.81 105.55 167.81 144.02 156.76 144.02 156.76 93.86"/>
      <path class="cls-1" d="M244.61,122.91a6.12,6.12,0,0,0-1.77-4.6,6.29,6.29,0,0,0-4.75-1.92,7.81,7.81,0,0,0-5.42,2,6.4,6.4,0,0,0-1.48,2.06,6.7,6.7,0,0,0-.61,2.44Zm9,15.16a16.87,16.87,0,0,1-6.45,5,19.32,19.32,0,0,1-8.07,1.77,21.72,21.72,0,0,1-7.48-1.27,18,18,0,0,1-6.09-3.65,17,17,0,0,1-4.07-5.7,19.32,19.32,0,0,1,0-14.88,17,17,0,0,1,4.07-5.7,17.8,17.8,0,0,1,6.09-3.65,21.73,21.73,0,0,1,7.48-1.28,17,17,0,0,1,6.69,1.28,13.8,13.8,0,0,1,5.1,3.65,16.72,16.72,0,0,1,3.23,5.7,23.15,23.15,0,0,1,1.13,7.44v3.33H230.58a8.41,8.41,0,0,0,2.77,4.85,7.82,7.82,0,0,0,5.24,1.81,8,8,0,0,0,4.43-1.17,12,12,0,0,0,3.15-3Z"/>
      <path class="cls-1" d="M287,126.81a8.91,8.91,0,0,0-2.23-6.31,9.36,9.36,0,0,0-12.54,0,10,10,0,0,0,0,12.61,9.36,9.36,0,0,0,12.54,0,8.87,8.87,0,0,0,2.23-6.3M296.81,144H287v-4.53h-.14a12,12,0,0,1-1.66,1.88,13.16,13.16,0,0,1-2.41,1.73,14.7,14.7,0,0,1-3.05,1.28,12,12,0,0,1-3.43.49,17.68,17.68,0,0,1-6.95-1.31,15.33,15.33,0,0,1-5.34-3.68,16.35,16.35,0,0,1-3.44-5.63,20.61,20.61,0,0,1-1.2-7.16,21.78,21.78,0,0,1,1.09-6.91,18.93,18.93,0,0,1,3.12-5.84,15.41,15.41,0,0,1,4.92-4.08,14.07,14.07,0,0,1,6.59-1.52,18.13,18.13,0,0,1,6.2,1,9.82,9.82,0,0,1,4.71,3.51h.15V90.46h10.62Z"/>
      <path class="cls-1" d="M304.41,109.59H315V144H304.41Zm-.85-10.84a6.16,6.16,0,1,1,6.17,6.17,6.18,6.18,0,0,1-6.17-6.17"/>
      <path class="cls-1" d="M340.87,128.93c-.8,0-1.81,0-3,.11a15.18,15.18,0,0,0-3.47.6,7.77,7.77,0,0,0-2.84,1.49,3.42,3.42,0,0,0-1.17,2.76,3.06,3.06,0,0,0,1.63,2.84,6.87,6.87,0,0,0,3.4.92,10.5,10.5,0,0,0,3-.43A8.87,8.87,0,0,0,341,136a5.76,5.76,0,0,0,1.81-2,5.67,5.67,0,0,0,.67-2.83v-2.27Zm2.62,10.77h-.14a10,10,0,0,1-4.71,4,16.26,16.26,0,0,1-6.2,1.2,15.92,15.92,0,0,1-4.64-.67,11.63,11.63,0,0,1-3.93-2,9.85,9.85,0,0,1-2.7-3.33,10.18,10.18,0,0,1-1-4.6,10.57,10.57,0,0,1,1.1-5,9.66,9.66,0,0,1,3-3.4,14.58,14.58,0,0,1,4.28-2.09,30.23,30.23,0,0,1,5-1.1,47.21,47.21,0,0,1,5.17-.43c1.72,0,3.32-.07,4.78-.07a5.52,5.52,0,0,0-2-4.5,7.26,7.26,0,0,0-4.78-1.66,10.29,10.29,0,0,0-4.78,1.1,12.91,12.91,0,0,0-3.86,3l-5.67-5.81a19.53,19.53,0,0,1,6.94-4.15,24.89,24.89,0,0,1,8.22-1.38,21.49,21.49,0,0,1,7.69,1.17,11.2,11.2,0,0,1,4.81,3.44,13.11,13.11,0,0,1,2.52,5.56,36.55,36.55,0,0,1,.71,7.61V144h-9.78Z"/>
    </svg>
  `

  return (
    <StyledLogo>
      <Link to="/" aria-label="Home pagina">
        <div dangerouslySetInnerHTML={{ __html: logo }} />
      </Link>
    </StyledLogo>
  )
}

export default Logo
