import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StyledPlaceholder } from './styles'
import styled from 'styled-components'

const StyledFigure = styled.figure`
  border-radius: var(--border-radius-medium);
  -webkit-mask-image: -webkit-radial-gradient(white, black); // safari fix
`

const ArticleImage = ({ image, alt = '', aspectRatio }) => {
  const img = getImage(image)

  if (!img) {
    return (
      <div>
        <StyledPlaceholder {...aspectRatio} />
      </div>
    )
  }

  return (
    <StyledFigure>
      <GatsbyImage image={img} alt={alt} />
    </StyledFigure>
  )
}

export default ArticleImage
